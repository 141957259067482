/* eslint-disable @typescript-eslint/no-explicit-any */
import { lazy, Suspense, useEffect, useState } from 'react';
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';
import { AEMReactCompMap, Heading, Types, Accordion } from '@marriott/mi-ui-library';
import { AccordionPropsAEM } from './AccordionContainer.types';
import { StyledAccordionContainer } from './AccordionContainer.styles';

export const AccordionContainerConfig = {
  emptyLabel: 'Accordion Container',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/accordioncontainer`,
};

export const AccordionEditableComponent = (props: any) => {
  return (
    <EditableComponent styleclass="row" config={AccordionContainerConfig} {...props}>
      <AccordionMarkup {...props} />
    </EditableComponent>
  );
};

export const AccordionContainer = (props: any) => {
  const { model } = props;
  const customAttributes = model?.trackingProperties?.enableScrollingBehavior
    ? { 'data-section-tracking': model?.componentId }
    : {};
  return model.styleclass?.includes('fullbleed') ? (
    <div className={`${props?.model?.styleclass ? props?.model?.styleclass : ''} m-container-fullbleed`}>
      <div {...customAttributes} className="container py-5">
        <AccordionEditableComponent {...props} />
      </div>
    </div>
  ) : (
    <div
      {...customAttributes}
      className={clsx([
        props?.model?.styleclass ? props?.model?.styleclass : '',
        'container',
        props?.model?.disablePadding ? 'py-0' : 'py-5',
      ])}
    >
      <AccordionEditableComponent {...props} />
    </div>
  );
};

const AccordionMarkup: React.FC<AccordionPropsAEM> = props => {
  const {
    disableAccInDesktop = false,
    disableAccInTabletAndDesktop = false,
    headerListItems,
    enableAccordionSeparator = false,
    allowedComponents,
  } = props;

  useEffect(() => {
    handleAccordion();
    window?.addEventListener('resize', () => handleAccordion());
  });

  const colDiv = (val1: number, val2: number) => Math.floor((val1 / val2) * 12);
  const mapper = AEMReactCompMap(allowedComponents);
  const [isFlex, setFlex] = useState(false);

  const handleAccordion = () => {
    const sm = window.matchMedia('(max-width: 767px)');
    const md = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
    const xl = window.matchMedia('(min-width: 992px)');

    if (disableAccInDesktop) {
      if (xl.matches) {
        setFlex(true);
      } else if (md.matches || sm.matches) {
        setFlex(false);
      }
    } else if (disableAccInTabletAndDesktop) {
      if (md.matches || xl.matches) {
        setFlex(true);
      } else if (sm.matches) {
        setFlex(false);
      }
    }
  };

  const AccordionComp = (comp: any, idx: number, headerListItem: any, styleClass: string, isOpen: boolean) => {
    return (
      <Accordion
        disableAccInDesktop={disableAccInDesktop}
        disableAccInTabletAndDesktop={disableAccInTabletAndDesktop}
        styleclass={styleClass}
        customClass={clsx([
          `${disableAccInDesktop ? 'isAccHiddenDesktop' : disableAccInTabletAndDesktop ? 'isAccHiddenTab' : ''}`,
          `col-12 ${
            disableAccInTabletAndDesktop
              ? `${`col-md-${colDiv(1, props?.totalNumberOfCards.length)}`}`
              : disableAccInDesktop
              ? `${`col-lg-${colDiv(1, props?.totalNumberOfCards.length)}`}`
              : `${props?.isAuthorMode ? 'isAccEnabledAll' : ''}`
          }`,
        ])}
        headerChildren={
          <Heading
            element={Types.tags.h4}
            variation={Types.headingType.subtitle}
            titleText={headerListItem.header || ''}
            fontSize={Types.size.large}
            customClass="m-accordion__title mb-0 text-left"
          />
        }
        enableAccordionSeparator={enableAccordionSeparator}
        id={`${props.componentId}_${idx}`}
        isOpen={isOpen}
      >
        {comp()}
      </Accordion>
    );
  };

  const isAuthorAccordionComponent = (idx: number, headerListItem: any) => {
    const AEMContainer = () => {
      return (
        <ResponsiveGrid
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={props?.pagePath}
          itemPath={`${props?.itemPath}/${props?.totalNumberOfCards[idx]}`}
          customClassName={clsx([
            `${disableAccInDesktop ? 'isAccHiddenDesktop' : disableAccInTabletAndDesktop ? 'isAccHiddenTab' : ''}`,
            'p-0',
          ])}
          config={{
            isEmpty: () => true,
            resourceType: 'mi-aem-common-spa/components/container',
          }}
        />
      );
    };
    return AccordionComp(AEMContainer, idx, headerListItem, '', true);
  };

  const isNonAuthorModeAccComp = (
    accItemName: string,
    idx: number,
    jsonData: any,
    headerListItem: any
  ): React.ReactNode => {
    if (Object.prototype.hasOwnProperty.call(jsonData, accItemName)) {
      const card = jsonData[accItemName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        if (Object.prototype.hasOwnProperty.call(cardItems, itemKey)) {
          const item = cardItems[itemKey];
          const itemType = item[':type']?.split('/').pop();
          const styleClass = item?.styleclass || '';
          if (Object.prototype.hasOwnProperty.call(mapper, itemType)) {
            const innerComp = mapper[itemType];
            const Component = lazy(() =>
              import(`../${innerComp}/index`).then((module: any) => ({ default: module[`${innerComp}`] }))
            );
            const accArgs = () => <Component {...item} />;
            return (
              <Suspense fallback={<></>}>{AccordionComp(accArgs, idx, headerListItem, styleClass, false)}</Suspense>
            );
          }
        }
      }
    }
    return null;
  };

  return (
    <StyledAccordionContainer
      data-component-name="o-common-static-accordioncontainer"
      className={clsx('col-12', { 'd-flex': isFlex })}
    >
      {props?.isAuthorMode
        ? Array.from({ length: props?.totalNumberOfCards?.length }, (_, i) =>
            isAuthorAccordionComponent(i, headerListItems[i])
          )
        : props?.totalNumberOfCards?.map((linkColName: string, idx: number) =>
            isNonAuthorModeAccComp(linkColName, idx, props?.cqItems, headerListItems[idx])
          )}
    </StyledAccordionContainer>
  );
};
